.noos-slider, .noos-slider_wrapper {
  position: relative; }

.noos-slider_wrapper {
  -webkit-transition: all 0.25s linear 0s;
  transition: all 0.25s linear 0s;
  margin: 0;
  padding: 0; }

.noos-slider_slide {
  margin: 0;
  padding: 0;
  list-style: none; }

.noos-slider.slide-horizontal .noos-slider_wrapper {
  overflow: hidden; }

.noos-slider.slide-horizontal .noos-slider_slide {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  -webkit-transform: translate(0, -100%);
      -ms-transform: translate(0, -100%);
          transform: translate(0, -100%);
  overflow: hidden; }
  .noos-slider.slide-horizontal .noos-slider_slide.will-show-next {
    -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
            transform: translate(100%, 0);
    -webkit-transition: all 0s linear 0s;
    transition: all 0s linear 0s; }
  .noos-slider.slide-horizontal .noos-slider_slide.active-hide-next {
    -webkit-transition: all 0.6s linear 0s;
    transition: all 0.6s linear 0s;
    -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
            transform: translate(-100%, 0); }
  .noos-slider.slide-horizontal .noos-slider_slide.will-show-prev {
    -webkit-transform: translate(-100%, 0);
        -ms-transform: translate(-100%, 0);
            transform: translate(-100%, 0);
    -webkit-transition: all 0s linear 0s;
    transition: all 0s linear 0s; }
  .noos-slider.slide-horizontal .noos-slider_slide.active-hide-prev {
    -webkit-transition: all 0.6s linear 0s;
    transition: all 0.6s linear 0s;
    -webkit-transform: translate(100%, 0);
        -ms-transform: translate(100%, 0);
            transform: translate(100%, 0); }
  .noos-slider.slide-horizontal .noos-slider_slide.active {
    -webkit-transition: all 0.6s linear 0s;
    transition: all 0.6s linear 0s;
    -webkit-transform: translate(0, 0);
        -ms-transform: translate(0, 0);
            transform: translate(0, 0); }

.noos-slider_pagination {
  font-size: 0;
  line-height: 0;
  padding: 0;
  margin: 10px 0; }
  .noos-slider_pagination-element {
    width: 10px;
    height: 10px;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #eee; }
